.ct-chart-white-colors .ct-grid {
  stroke: rgba(255, 255, 255, 0.2);
  stroke-width: 1px;
  stroke-dasharray: 2px;
}

.ct-chart-white-colors .ct-series-a .ct-point, .ct-chart-white-colors .ct-series-a .ct-line, .ct-chart-white-colors .ct-series-a .ct-bar, .ct-chart-white-colors .ct-series-a .ct-slice-donut {
  stroke: rgba(255, 255, 255, 0.9);
}

.ct-chart-white-colors .ct-label {
  align-items: flex-end;
  justify-content: flex-end;
  text-align: right;
  text-anchor: end;
}

.ct-chart-white-colors .ct-label.ct-horizontal.ct-end {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  text-anchor: middle;
}

.ct-chart-white-colors .ct-label {
  color: rgba(255, 255, 255, 0.7);
}

.ct-chart-line .ct-label, .ct-chart-bar .ct-label {
  display: block;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.ct-label {
  fill: rgba(0, 0, 0, 0.4);
  line-height: 1;
}

.ct-series-a .ct-point, .ct-series-a .ct-line, .ct-series-a .ct-bar, .ct-series-a .ct-slice-donut {
  stroke: #00bcd4;
}

.ct-series-a .ct-slice-pie, .ct-series-a .ct-slice-donut-solid, .ct-series-a .ct-area {
  fill: #00bcd4;
}
