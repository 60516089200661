// color mixins

//GRAY
$gray-0:#f8f9fa;
$gray-1:#f1f3f5;
$gray-2:#e9ecef;
$gray-3:#dee2e6;
$gray-4:#ced4da;
$gray-5:#adb5bd;
$gray-6:#868e96;
$gray-7:#495057;
$gray-8:#343a40;
$gray-9:#212529;

//BLUE
$blue-0:#e7f5ff;
$blue-1:#d0ebff;
$blue-2:#a5d8ff;
$blue-3:#74c0fc;
$blue-4:#4dabf7;
$blue-5:#339af0;
$blue-6:#228be6;
$blue-7:#1c7ed6;
$blue-8:#1971c2;
$blue-9:#1864ab;

//YELLOW
$yellow-0:#fff9db;
$yellow-1:#fff3bf;
$yellow-2:#ffec99;
$yellow-3:#ffe066;
$yellow-4:#ffd43b;
$yellow-5:#fcc419;
$yellow-6:#fab005;
$yellow-7:#f59f00;
$yellow-8:#f08c00;
$yellow-9:#e67700;

//RED
$red-0:#fff5f5;
$red-1:#ffe3e3;
$red-2:#ffc9c9;
$red-3:#ffa8a8;
$red-4:#ff8787;
$red-5:#ff6b6b;
$red-6:#fa5252;
$red-7:#f03e3e;
$red-8:#e03131;
$red-9:#c92a2a;
