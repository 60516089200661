@charset "UTF-8";
@import './colors';
// common class
$border-color: $gray-5;
$default-blue: #00acc1;

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.clear {
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

.block {
  display: block;
  width: 100%;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-bold {
  font-weight: 500;
}

.link {
  text-decoration-line: underline;
  cursor: pointer;
  color: $default-blue;

  &:hover, &:focus, &:active {
    font-weight: 600;
    text-shadow: $gray-2 1px 0 7px;
  }
}

.error-text {
  color: red;
  font-size: 0.8rem;
}

.info-text {
  color: $gray-6;
  font-weight: 500;
  font-size: 0.8rem;
}

// 테이블 공통 스타일
.table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;

  tr {
    text-align: center;
    height: 2.5rem;

    th {
      background-color: rgba(0, 172, 193, 0.1);
    }

    th, td {
      font-weight: normal;
      padding: 0 0.5rem;
      //border: 1px solid $border-color;

    }
  }

  tbody {
    tr {
      &:nth-child(even) {
        background-color: $gray-1;
      }
    }
  }

  .text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

p {
  margin-bottom: 0 !important;
}

.form-outer-wrapper {
  max-width: 900px;
}

.form-wrapper {
  legend {
    color: $gray-7 !important;
  }

  label {
    color: $gray-7 !important;
  }

  > div {
    > div + div {
      margin-top: 1rem;
    }
  }

  .legend {
    font-weight: 500;
    padding-left: 0.45rem;
    font-size: 0.9rem;
    color: #495057 !important;
    margin-bottom: 0;
  }
}

.delete-button {
  background-color: red;
  border: 1px solid red;
  border-radius: 2px;
  color: #fff;
  cursor: pointer;
  font-weight: 500;
  padding: 0.13rem 0.35rem 0.3rem 0.35rem;

  &:hover {
    box-shadow: 0 14px 26px -12px rgba(0, 0, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  }
}

.square-outer {
  width: 100%;
  position: relative;

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

.square-inner {
  position: absolute;
  width: 100%;
  height: 100%;
}

.handle-image-wrapper {
  padding-left: 2rem;

  > div {
    margin-top: 0.2rem;
    float: left;
  }

  > p {
    float: left;
    width: calc(100% - 9rem);
    border: 1px solid $gray-2;
    height: 2rem;
    padding: 0.25rem 0.5rem 0 0.5rem;
    margin-top: 2px;
    //height: 100%;
  }

  > button {
    float: left;
    margin-left: 0.5rem;
  }

  h5 {
    font-weight: 500;
    padding-left: 0.45rem;
    font-size: 0.9rem;
    color: #495057 !important;
    margin-bottom: 0;
  }
}

.filter-wrapper {
  padding-top: 2.5rem;

  .filter-select-wrapper {
    margin-top: 0.5rem;
  }
}

.article-desc-wrapper {
  padding-left: 1rem;
  margin-top: 0.7rem;

  > legend {
    font-weight: 500;
    font-size: 0.9rem;
    color: #495057 !important;
    margin-bottom: 0;
  }
}

.checkbox-wrapper {
  > legend {
    font-weight: 500;
    padding-left: 0.45rem;
    font-size: 0.9rem;
    color: #495057 !important;
    margin-bottom: 0;
  }
}

.date-filter-wrapper {
  width: 100%;

  > * {
    float: left;
  }

  > p {
    width: 2rem;
    margin-top: 2rem;
    text-align: center;
  }

  > div {
    width: calc((100% - 2rem) / 2);
  }
}

.text-none {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

